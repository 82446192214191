import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import BottomNavigationMobile from "./bottomnavigationmobile"

import UserContext from "../../../../context/UserContext"
import Logoutbutton from "../../../account/logoutbutton"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { ButtonWildMen } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWildMen"
import { TextElementMen } from "@nutrafol/nutrafol-ui-kit/dist/TextElementMen"

import AccountIcon from "../../../../assets/icons/wild/account-icon-wild.svg"
import LeftArrow from "../../../../assets/icons/wild/arrow-left-nav-white.svg"
import RightArrow from "../../../../assets/icons/wild/arrow-right-nav-white.svg"

import { navigate } from "@reach/router"
import NavigationShopBannerMobile from "../concierge/navigationshop-banner-mobile"

const MenuItem = styled.button`
  width: 100%;
  text-align: left;
  padding: 12px 0;
`
const MenuItemLink = styled(Link)`
  display: block;
  width: 100%;
  text-align: left;
  padding: 12px 0;
`
const Nav = styled.nav`
  display: none;
  position: fixed;
  background: #464d52;
  border-top: 1px #383e43 solid;
  @media (max-width: 430px) {
    .nav-item {
      span.special-br {
        display: block;
      }
    }
  }

  &.product-page {
    border-top: 1px #c8d2d1 solid;
    background: #fff;

    .nav-item .textelement {
      color: #07253a !important;
    }

    .nav-item .textelement.btn-green {
      color: #008078 !important;
    }

    .back-area *,
    .nav-item-link-container * {
      stroke: #07253a !important;
    }

    .products {
      background: ${theme.wild.color.men.base.secondary};

      &:hover,
      &:active,
      &:focus {
        background: ${theme.wild.color.men.secondary.alt};
      }
    }

    .menu-bottom * {
      background: #f8f8f5;
      border-radius: 4px;
      color: #07253a !important;
      fill: #07253a !important;
    }
  }

  /* backdrop-filter: blur(20px); */
  width: 100%;
  height: calc(100% - 96px);
  left: 0;
  bottom: 0;
  padding: 0;
  z-index: 12;
  overflow-y: scroll;
  top: 96px;
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 0.2);
  @media (min-width: 768px) {
    top: 84px;
  }
  @media (min-width: 900px) {
    top: 100px;
    left: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 126px);
  }
  @media (min-width: 1024px) {
    display: none;
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    .navigation-logo {
      display: none;
    }
  }
`
const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  ul {
    padding-top: 24px;
    height: 100%;
  }

  &.navIsOpen {
    animation: closeSubMenu 0.25s;
    -moz-animation: closeSubMenu 0.25s;
    -webkit-animation: closeSubMenu 0.25s;
  }

  &.menuIsOpen {
    left: -100%;
    animation: openSubMenu 0.25s;
    -moz-animation: openSubMenu 0.25s;
    -webkit-animation: openSubMenu 0.25s;
  }
`
const ArrowSection = styled.div`
  display: inline-block;
  float: right;
  width: auto;
  height: auto;
  padding: 10px 0 0 0;
  text-align: right;

  svg {
    width: 8px;
    height: 16px;
  }

  &.nav-subitem-link-container {
    padding: 35px 0 0 0 !important;
  }

  &.nav-subitem-link-container.has-subtext {
    padding: 51px 0 0 0 !important;
  }
`

const AccountIconSection = styled.div`
  display: inline-block;
  float: right;
  width: auto;
  height: auto;
  padding: 12px 0 0 0;
  text-align: right;

  &.account {
    padding: 6px 0 0 0;

    svg {
      width: 16px;
    }
  }

  &.nav-subitem-link-container {
    padding: 35px 0 0 0 !important;
  }

  &.nav-subitem-link-container.has-subtext {
    padding: 51px 0 0 0 !important;
  }

  @media (min-width: 768px) {
    padding: 10px 0 0 0;
  }
`
const NavItem = styled.li`
  &.active {
    > ul {
      display: flex;
      flex-direction: column;
    }
  }
`
const NavItemWrapper = styled.div`
  margin: 0 40px;
  cursor: pointer;

  a {
    &:visited {
      text-decoration: none;
    }
  }
`
const NavItemWrapperChild = styled.div`
  margin: 0;
`
const AdditionalBoxWrapper = styled.div`
  padding: 0 24px;

  &.imagem {
    padding: 0 12px;
  }
`
const SubMenu = styled.ul`
  display: none;
  position: absolute;
  left: 100%;
  overflow-y: auto;
  top: 0;
  width: 100%;
  margin: 0;
`
const SubItem = styled.li`
  padding: ${theme.spacing.small} ${theme.spacing.medium};
  margin: 0 24px 8px;
  border-radius: 4px;
  //background: rgba(255, 255, 255, 0.05);

  &.products {
    background: rgba(255, 255, 255, 0.05);
    margin: 2px 16px;
    padding: 16px 8px 16px 8px;
  }

  &.subcategory-product {
    padding: 8px 15px 8px 2px;
  }

  &.subcategory-account {
    padding: 0 15px 0 2px;

    a {
      padding: 14px 0;
      display: block;
    }
  }

  .gatsby-image-wrapper {
    height: auto;
  }
`
const Details = styled.li`
  p {
    padding: 6px 0 16px 0;
  }
`
const AdditionalBox = styled(Flex)`
  margin: ${theme.spacing.medium} 0;

  .additional-image-icon {
    width: 120px;
    margin: 35px 0 0 5%;
  }
`
const BackArea = styled.li`
  margin: 8px 24px 24px 36px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  svg {
    display: inline-block;
    margin-right: 22px;
    width: 8px;
    height: 16px;
  }

  p {
    display: inline-block;
  }
`

const ImageBox = styled(Box)`
  position: relative;

  .icon-wrap {
    position: absolute;
    top: -10px;
    left: 10px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  img {
    width: 100%;
  }

  .subitem-image {
    width: 110px;
  }
`

const MobileNavBackButton = styled.button``

const ctaText = "Take the Quiz"

const MultiLevelNavigation = (props) => {
  const {
    data,
    activeItem,
    setActiveItem,
    activeItemIndex,
    toggleNav,
    navIsOpen,
    toggleMenu,
    toggleSecondLevel,
    secondLevelIsOpen,
    menuIsOpen,
    toggleScroll,
    deactivateSecondLevelMenu,
    isProductPage,
  } = props

  const trackExpanded = (text) => {
    segmentEvent("Navigation Expanded", {
      module_location: "navigation",
      text,
    })
  }

  const trackClicked = (text, destination) => {
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      type: "button",
      text,
      destination,
    })
  }

  const makeNavItemActive = (index, text) => {
    setActiveItem(index)
    toggleMenu(true)
    toggleSecondLevel(true)
    setFocus(`SubItemLink-${index}-0`)
    trackExpanded(text)
  }

  const handleLinkClick = (text, destination, donav = false) => {
    toggleScroll(true)
    setTimeout(toggleNav(false), 50)
    toggleMenu(false)
    setActiveItem(null)
    toggleSecondLevel(false)
    trackClicked(text, destination)
    if (destination !== "#" && donav) {
      navigate(destination)
    }
  }

  const setFocus = (id) => {
    setTimeout(() => {
      try {
        document.getElementById(id).focus()
      } catch (e) {
        console.error(`${id} setFocus error`)
      }
    }, 250)
  }

  const onClick = () => {
    segmentEvent("Marketing CTA Clicked", {
      destination: "/men/quiz",
      module_location: "navigation",
      text: ctaText,
      type: "button",
    })
    setTimeout(() => {
      window.location = process.env.GATSBY_MAGENTO_URL + "men/quiz/"
    }, 500)
  }

  const AdditionalBoxItem = (item) => {
    return (
      <AdditionalBox>
        {item.additional.imagem && (
          <Box width={0.33} className="additional-image-icon">
            <img
              src={item.additional.imagem}
              className={`additional-product-image`}
              alt={item.additional.name}
            />
          </Box>
        )}
        <Box width={item.additional.imagem ? 0.66 : 1} className="">
          <AdditionalBoxWrapper
            className={`item-children-${item.children.length} ${
              item.additional.imagem ? "imagem" : ""
            } `}
          >
            <Details>
              <TextElementMen
                element="h4"
                text={item.additional.name}
                className="title--small text-master-base-default uppercase"
              />
              <TextElementMen
                element="p"
                text={item.additional.subtext}
                className="body--large text-master-base-default"
              />
              <ButtonWildMen
                textLink
                arrowRight
                label={item.additional.link.name}
                onClick={() =>
                  item.additional.link.url.indexOf("http") === 1
                    ? navigate(item.additional.link.url)
                    : (window.location = item.additional.link.url)
                }
              />
            </Details>
          </AdditionalBoxWrapper>
        </Box>
      </AdditionalBox>
    )
  }

  const renderAccountLinks = (item, index) => {
    return (
      <React.Fragment key={index}>
        <UserContext.Consumer>
          {(userState) => (
            <>
              {userState.isLoggedIn ? (
                <NavItem
                  className={classNames("nav-item", {
                    "has-children": !!item.children,
                    active: index === activeItem,
                  })}
                  key={index}
                >
                  <MenuItem
                    onClick={() => makeNavItemActive(index, item.link.text)}
                    aria-label={`Open ${item.link.text} menu`}
                    tabIndex={6 + index}
                  >
                    <NavItemWrapper>
                      <TextElementMen
                        element="span"
                        text={item.link.text}
                        className="title--large-i text-master-base-default uppercase"
                        style={{ color: theme.wild.color.master.base.black }}
                      />
                      <ArrowSection className="nav-item-link-container">
                        <RightArrow />
                      </ArrowSection>
                    </NavItemWrapper>
                  </MenuItem>
                  <>
                    {!!item.children && (
                      <SubMenu>
                        {navIsOpen &&
                          data[activeItemIndex] &&
                          data[activeItemIndex].link.text && (
                            <BackArea className={`back-area`}>
                              <MobileNavBackButton
                                onClick={() => deactivateSecondLevelMenu()}
                              >
                                <LeftArrow />
                                <TextElementMen
                                  element="p"
                                  text={data[activeItemIndex].link.text}
                                  className="mono--small text-master-base-default uppercase"
                                  style={{
                                    color: theme.wild.color.master.base.black,
                                  }}
                                />
                              </MobileNavBackButton>
                            </BackArea>
                          )}
                        {item.children.map((subitem, idx) => {
                          if (subitem.name === "Log Out") {
                            return (
                              <React.Fragment key={`subitem-${idx}`}>
                                <SubItem
                                  key={`subitem-${idx}`}
                                  className="subcategory-account"
                                >
                                  <NavItemWrapper>
                                    <Logoutbutton
                                      isMen={true}
                                      fontClass={
                                        "textelement title--medium text-master-base-default uppercase"
                                      }
                                    />
                                  </NavItemWrapper>
                                </SubItem>
                              </React.Fragment>
                            )
                          }
                          return (
                            <React.Fragment key={`subitem-${idx}`}>
                              {subitem.url && (
                                <SubItem
                                  key={`subitem-${idx}`}
                                  className="subcategory-account"
                                >
                                  <Link
                                    onClick={() =>
                                      handleLinkClick(subitem.name, subitem.url)
                                    }
                                    to={subitem.url}
                                    aria-label={`Open ${subitem.name} page`}
                                    id={`SubItemLink-${index}-${idx}`}
                                  >
                                    <NavItemWrapper>
                                      <TextElementMen
                                        element="h4"
                                        text={subitem.name}
                                        className="title--medium text-master-base-default uppercase"
                                        styles={{ marginBottom: "2px" }}
                                      />
                                    </NavItemWrapper>
                                  </Link>
                                </SubItem>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </SubMenu>
                    )}
                  </>
                </NavItem>
              ) : (
                <NavItem className="nav-item" key={index}>
                  <MenuItem
                    onClick={() =>
                      handleLinkClick(
                        "Account",
                        "/customer/account/login/",
                        true
                      )
                    }
                    aria-label={`Go to login page`}
                    tabIndex={10}
                  >
                    <NavItemWrapper>
                      <TextElementMen
                        element="span"
                        text={"Login"}
                        className="title--large-i text-master-base-default uppercase"
                      />
                      <AccountIconSection className="nav-item-link-container account">
                        <AccountIcon />
                      </AccountIconSection>
                    </NavItemWrapper>
                  </MenuItem>
                </NavItem>
              )}
            </>
          )}
        </UserContext.Consumer>
      </React.Fragment>
    )
  }

  const navLinks = data.map((item, index) => {
    if (item.name !== "account") {
      return (
        <NavItem
          className={classNames("nav-item", {
            "has-children": !!item.children,
            active: index === activeItem,
          })}
          key={index}
        >
          {!!item.children ? (
            <>
              <MenuItem
                onClick={() => makeNavItemActive(index, item.link.text)}
                aria-label={`Open ${item.link.text} menu`}
                tabIndex={6 + index}
              >
                <NavItemWrapper>
                  <TextElementMen
                    element="span"
                    text={item.link.text}
                    className="title--large text-master-base-default uppercase"
                    style={{ color: theme.wild.color.master.base.black }}
                  />
                  <ArrowSection className="nav-item-link-container">
                    <RightArrow />
                  </ArrowSection>
                </NavItemWrapper>
              </MenuItem>
              <SubMenu>
                {navIsOpen &&
                  data[activeItemIndex] &&
                  data[activeItemIndex].link.text && (
                    <BackArea className={`back-area`}>
                      <MobileNavBackButton
                        onClick={() => deactivateSecondLevelMenu()}
                      >
                        <LeftArrow />
                        <TextElementMen
                          element="p"
                          text={data[activeItemIndex].link.text}
                          className="mono--small text-master-base-default uppercase"
                          style={{ color: theme.wild.color.master.base.black }}
                        />
                      </MobileNavBackButton>
                    </BackArea>
                  )}
                {item.children.map((subitem, idx) => {
                  const NavIcon = subitem?.icon
                  return (
                    <SubItem
                      key={`subitem-${idx}`}
                      className={data?.[
                        activeItemIndex
                      ]?.link?.text.toLowerCase()}
                    >
                      <Link
                        onClick={() =>
                          handleLinkClick(subitem.name, subitem.url)
                        }
                        to={subitem.url}
                        aria-label={`Open ${subitem.safeName} page`}
                        id={`SubItemLink-${index}-${idx}`}
                      >
                        <NavItemWrapperChild>
                          {subitem.image ? (
                            <Flex flexWrap="wrap" alignItems="center">
                              <ImageBox
                                flexBasis={["100px", "100px", "33%"]}
                                mr={8}
                                className={`image-icon image-icon-${idx}`}
                              >
                                {subitem?.icon && (
                                  <div className="icon-wrap">
                                    <img src={NavIcon} alt={``} />
                                  </div>
                                )}
                                <img
                                  src={`${subitem.image}`}
                                  alt={subitem.safeName}
                                  className="subitem-image"
                                />
                              </ImageBox>
                              <Box flex="1">
                                <TextElementMen
                                  element="h4"
                                  text={subitem.title}
                                  className="title--medium text-master-base-default uppercase mb-2"
                                />
                                <TextElementMen
                                  element="p"
                                  text={subitem.subtext}
                                  className="body--small text-master-base-default block"
                                />
                              </Box>
                            </Flex>
                          ) : (
                            <Flex className="row align-center">
                              <Box width={1}>
                                <TextElementMen
                                  element="h4"
                                  text={subitem.name}
                                  className="title--medium text-master-base-default uppercase"
                                />
                              </Box>
                            </Flex>
                          )}
                        </NavItemWrapperChild>
                      </Link>
                    </SubItem>
                  )
                })}
                <Box px={[15]} pb={[9]}>
                  <NavigationShopBannerMobile trackClicked={trackClicked} />
                </Box>
                {!!item.additional && <>{AdditionalBoxItem(item)}</>}
                <li style={{ margin: "15px 24px" }}>
                  <ButtonWildMen
                    primary
                    label={ctaText}
                    style={{ width: "100%", fontSize: "16px" }}
                    onClick={onClick}
                  />
                </li>
              </SubMenu>
            </>
          ) : (
            <MenuItemLink
              onClick={() => handleLinkClick(item.name, item.link.url)}
              to={item.link.url}
              aria-label={`Go to ${item.link.text} page`}
              tabIndex={6 + index}
            >
              <NavItemWrapper>
                <TextElementMen
                  element="span"
                  text={item.link.text}
                  className="title--large text-master-base-default uppercase"
                  style={{ color: theme.wild.color.master.base.black }}
                />
              </NavItemWrapper>
            </MenuItemLink>
          )}
        </NavItem>
      )
    } else {
      return (
        <React.Fragment key={index}>
          {renderAccountLinks(item, index)}
        </React.Fragment>
      )
    }
  })

  return (
    <Nav
      className={classNames("mobile-navigation", {
        "product-page": isProductPage,
      })}
    >
      <MenuWrapper
        className={classNames("menu-wrapper", {
          menuIsOpen: menuIsOpen,
          navIsOpen: navIsOpen,
        })}
      >
        <ul>
          {navLinks}
          {!secondLevelIsOpen && (
            <li style={{ margin: "15px 24px" }}>
              <ButtonWildMen
                primary
                label={ctaText}
                style={{ width: "100%", fontSize: "16px" }}
                onClick={onClick}
              />
            </li>
          )}
        </ul>
      </MenuWrapper>
      {!secondLevelIsOpen && <BottomNavigationMobile toggleNav={toggleNav} />}
    </Nav>
  )
}

export default MultiLevelNavigation
